import React from "react";
import {Grid, Typography, TextField, FormControlLabel, Checkbox, Container, Button, Box, Link} from "@mui/material";
import { useSelector } from "react-redux";
import PrintOrder from "../functions/printOrderFunctions";
import { doc, setDoc } from "firebase/firestore"; 
import {firestore} from "../firebase";
import { v4 as uuId } from 'uuid';
import { BasketDrawerContext } from "../components/basketDrawer";
import {CustomerOrderConverter, CustomerOrder} from "../models/CustomerOrderModel";

import {clearBasket} from "../redux/cartRedux";
import { useDispatch } from 'react-redux';

function Checkout(){
    return (
        <Container sx={{m:"30px auto", width: '60%'}}>
            <Typography variant="h4">Your order</Typography>
                <Container sx={{m:"30px auto", width: '90%'}}>
                    <CustomerOrderForm />
                </Container>
        </Container>
    );
}

async function Order(firstname = "t_name", lastname = "t_lastname", email = "t_email", streetAddress = "t_streetAddress", city = "t_city", postalcode = "t_postcode", country = "t_country", products){
    const id = uuId();
    const customerOrder = new CustomerOrder(id, firstname, lastname, email, streetAddress, city, postalcode, country, products); 
    
    //store order to database
    const ref = doc(firestore, "users", id).withConverter(CustomerOrderConverter);

    console.log(customerOrder);
    //setDoc(ref, customerOrder);

    PrintOrder(id, firstname, lastname, email, streetAddress, city, postalcode, country, products)
}


function CustomerOrderForm() {

    const cartProducts = useSelector(state=>state.cart.products);
    const dispatch = useDispatch();
    let products = [];
    for (const key in cartProducts){
        products.push({uid: cartProducts[key].item.uid, variant: cartProducts[key].variant});
    }

    const [form, setForm] = React.useState({
        firstname: "",
        lastname: "",
        email: "",
        streetAddress: "",
        city: "",
        postalcode: "",
        country: "",
    });

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

  return (
    <div>
        <form name="checkout-form">
            <Typography variant="h5" sx={{pb:2}}>
                Shipping Information
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="firstname"
                    name="firstname"
                    label="First name"
                    fullWidth
                    autoComplete="fname"
                    value={form.firstname}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label="Last name"
                    fullWidth
                    autoComplete="lname"
                    value={form.lastname}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    autoComplete="email address"
                    value={form.email}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    required
                    id="streetAddress"
                    name="streetAddress"
                    label="Streetname & number"
                    fullWidth
                    autoComplete="Streetname & number"
                    value={form.streetAddress}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    autoComplete="billing address-level2"
                    value={form.city}
                    onChange={handleChange}
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="postalcode"
                    name="postalcode"
                    label="Postal code"
                    fullWidth
                    autoComplete="billing postal-code"
                    value={form.postalcode}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={12} sm={12}>
                <TextField
                    required
                    id="country"
                    name="country"
                    label="Country"
                    fullWidth
                    autoComplete="billing country"
                    value={form.country}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={12}>
                    
                <FormControlLabel
                    control={<Checkbox required color="secondary" name="TermsAndConditions" value="yes" />}
                    label={
                        <Typography>I agree with all the applying&nbsp;
                        <Link href="/terms" rel="noreferrer" target={"_blank"} color="inherit">
                            Terms and Conditions.
                        </Link>
                        *
                        </Typography>
                    }
                />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Button onClick={()=>Order(form.firstname, form.lastname, form.email, form.streetAddress, form.city, form.postalcode, form.country, products)} variant="contained"> TEST PRINT ORDER BACKEND</Button>
                    </Box>
                </Grid>

            </Grid>
      </form>
    </div>
  );
}

export default Checkout;
