import { styled } from '@mui/system';
import { Link, NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {color: theme.palette.secondary.main},

}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {color: theme.palette.secondary.main},
    '&.active': {
        color: theme.palette.secondary.main, // Change this to your preferred active color
        textShadow: `-0.06ex 0 0 ${theme.palette.secondary.main}, 0.06ex 0 0 ${theme.palette.secondary.main}`, // Adjust the shadow properties as needed
      },
}));

const GenericBanner = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    background: '#fafafa',
    backgroundImage: `url(${process.env.PUBLIC_URL}/bgPatterns/pattern_2.png)`,
    backgroundRepeat: 'repeat', // You can use 'repeat-x' or 'repeat-y' for single-axis repeats
    margin:'0px',
}));


export {StyledLink, StyledNavLink, GenericBanner}