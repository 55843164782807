import { collection, getDocs, doc } from "firebase/firestore";
import {storage, firestore} from "../firebase";
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Typography, Paper, ButtonBase, Button, Grid, useMediaQuery, Divider } from '@mui/material';
import myImage from '../assets/logo512.png';
import { useEffect, useState } from "react";

import { CartItemSmall } from "./cartItem";

const GenericBanner = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '20px',
    background: '#D9F6FF55',
}));

export default function RecommendedBanner(){
    const theme = useTheme();

    //todo rewrite this state to use redux and persist all the data on the frontend as to minimize the number of requests.
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const getItems = async() =>{
            setLoading(true);
            const items = [];
            const querySnapshot = await getDocs(collection(firestore, "artworks"));
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                const itemData = doc.data(); // Get the document data
                // Add the document ID as a property in the data object
                itemData.linkId = doc.id;
                items.push(itemData);
            });
            setItems(items);
            setLoading(false);
        }

        try{
            getItems();
        } catch(err){
            console.error(err);
        }
    }, []);

    let itemList = items.concat(items).concat(items);
    itemList.length = 5;

    return(
        <GenericBanner sx={{ background: '#D9F6FF55'}}>
            {/*<Divider orientation="horizontal" sx={{backgroundColor:'#ff0000'}} />*/}
            <Container>
                <Typography sx={{display:"inline"}}>Related products</Typography>
                
                <Typography sx={{display:"inline", float:'right'}} align="right">More &gt;</Typography>
                <Grid container rowSpacing={1} columnSpacing={1}>     
                    {itemList.map(item => {
                        return(
                            <Grid key={item.name+Math.random()} item xs={2.4}>
                                <CartItemSmall item={item} linkId={item.linkId} key={item.name} src={item.source} name={item.name} keywords={item.keywords} price={item.price} prompt_string={item.prompt_string} info={item.info} card_height={200}/>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </GenericBanner>
    )
}