import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import React, { useState, useEffect, useContext} from "react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RemoveIcon from '@mui/icons-material/Remove';
import { Slide, TableFooter } from "@mui/material";
import {Typography} from '@mui/material';
import { TableContainer, TableBody, TableCell, TableHead, TableRow, Table, Paper} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CartItemBasket } from './cartItem';
export const BasketDrawerContext = React.createContext();

export function BasketContextProvider(props){
    const [isDisplayed, setIsDisplayed] = useState(false);
    
    return (
        <BasketDrawerContext.Provider value={{isDisplayed, setIsDisplayed}}>
            {props.children}
        </BasketDrawerContext.Provider>
    )
}

export function BasketDrawer() {
    const basketCtx = useContext(BasketDrawerContext);
    const cartProducts = useSelector(state=>state.cart.products);
  return (
    <div>
        <Drawer
            anchor='right'
            open={basketCtx.isDisplayed}
            onClose={()=>{basketCtx.setIsDisplayed(false)}}
        > 
        <Box
            sx={{width:250 }}
            role="presentation"
        >
            <Typography variant="h6" textAlign="center" sx={{mt:1, p:0}}>
                Basket
            </Typography>
            <Divider variant="middle"></Divider>
            <List sx={{mt:0, pt:0}}>
                {cartProducts.map(({item, variant, uid}, index) => (
                    <ListItem key={uid}>
                        <Box width='100%'>
                            <CartItemBasket uid={uid} key={item.name} src={item.source} name={item.name} keywords={item.keywords} price={item.price} prompt_string={item.prompt_string} info={item.info} card_height={200} variant={variant}/>
                        </Box>
                    </ListItem>
                ))}

                <ListItem>
                    <BasketTotal></BasketTotal>
                </ListItem>
            </List>

      </Box>
      
        </Drawer>
    </div>
  );
}

function BasketTotal(){
    const basketCtx = useContext(BasketDrawerContext);
    const cartTotal = useSelector(state=>state.cart.total);
    let navigate = useNavigate();
    return(
        <TableContainer component={Paper}>
            <Table sx={{ width:'100%' }} size="small" aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography>Summary</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component="th" scope="row">
                    Items
                    </TableCell>
                    <TableCell align="right">
                        &euro;{cartTotal}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                    Shipping
                    </TableCell>
                    <TableCell align="right">
                        &euro;5,95
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                    Total
                    </TableCell>
                    <TableCell align="right">
                        &euro;{cartTotal + 5.95}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center" colSpan="2">
                        <Button width="100%" color="secondary" variant="contained" onClick={()=>{navigate("/checkout"); basketCtx.setIsDisplayed(false);}}>Go To Checkout!</Button>
                    </TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    );
}