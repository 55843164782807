import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import {ListItemIcon, ListItemButton,Divider, Grid, Paper, AppBar} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';

const pages = [
    {title: 'About', url: '#'},
    {title: 'My Table', url: '#'},
    {title: 'Home', url: '#'}, 
];

const GenericBanner = styled(AppBar)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '80px',
    padding: '0px',
    margin: '0px',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
}));

function FooterCopyright() {

  const theme = useTheme();

  return (
<GenericBanner>
            <Container sx={{width:'auto', py:0}}>
                    <Typography 
                    variant="h6" align="center"
                    noWrap sx={{ 
                                width:'auto',
                                fontWeight: 500,
                                letterSpacing: '.3rem', 
                            }}>
                    Copyright Prompted
                </Typography>
            </Container>
 </GenericBanner>
  );
}
export default FooterCopyright;