import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemButton,Divider} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { StyledNavLink } from './global';

const pages = [
    {title: 'Vaporwave', url: 'vaporwave'},
    {title: 'Nature', url: 'nature'},
    {title: 'Minimalism', url: 'minimalism'},
    {title: 'Artsy', url: 'artsy'}, 
    {title: 'Sci-fi', url: 'sci-fi'}, 
    {title: 'Realism', url: 'realism'}, 
];

const StyledNavBar = styled('AppBar')({
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      zIndex: "3",
    },
    minHeight: {
      minHeight: "5px !important",
    },
  smallTypo:{
  fontSize:"5px"
  }
  });

const hoverButton = {
    "&:hover": {
        border: "1px solid #ff00ff",
        color: "grey",
        backgroundColor: "black",
    }
}



function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
        position="sticky"
        elevation={2}
        sx={{minHeight: "10px", borderBottom: (theme) => `2px solid ${theme.palette.divider}`, my:0, zIndex:1000}}
        color="shadedPrimary"
    >
    <Container sx={{width:1}}>
        <Toolbar variant="dense" sx={{minHeight: "10px", flexWrap: 'wrap'}}>
            {pages.map((page) => (
                <div key={page.title}>
                  <Button color="inherit" variant="text" size="small" 
                          sx={{"&.MuiButton-text": { color: (theme) => theme.palette.divider },
                          '&:hover': {backgroundColor: (theme) => theme.palette.primary.dark},
                          m: 0,
                          height: 10
                      }}>
                      <StyledNavLink to={`/collection/${page.url}`}>
                        {page.title}
                      </StyledNavLink>
                  </Button>
                </div>
            ))}
        </Toolbar>
    </Container>
  </AppBar>
  );
}
export default NavBar;