import { TypoGraphy, useMediaQuery } from "@mui/material";
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Typography, Paper, ButtonBase, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import { GenericBanner } from "./global";
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function Banner({title, content, image}){
    
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <GenericBanner sx={{ py: isSmall ? 0 : 5 }}>
            <Container>
                <Grid container spacing={isSmall ? 2 : 2}>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{justifyContent: 'center'}}>
                            <Img alt="complex" src={image} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ pt: 0, px: 3 }}>
                            <Typography variant="h2" component="div" gutterBottom>
                                {title}
                            </Typography>
                            <Typography variant="body2" align="justify" gutterBottom sx={{letterSpacing: '0.8px', lineHeight: '1.7'}}>
                            {content}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </GenericBanner>
    )
}