import React from 'react';
import {getDoc, doc } from "firebase/firestore";
import {firestore} from "../firebase";
import {Container,Typography, Grid, Item, Button} from "@mui/material";
import {CartItemBig} from "../components/cartItem";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import RecommendedBanner from '../components/productsBanner';

function ItemPage() {
    const [item, setItem] = useState("");
    const [loading, setLoading] = useState(false);
    const {uid} = useParams();

    useEffect(() => {
        const getItem = async() =>{
            setLoading(true);

            const docRef = doc(firestore, "artworks", uid);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
              //console.log("Document data:", docSnap.data());
              //convert date to string for the redux store
              var itemT = docSnap.data();
              itemT.date = JSON.stringify(itemT.date);
              setItem(itemT);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such item in the database!");
            }

            setLoading(false);
        }

        try{
            getItem();
        } catch(err){
            console.error(err);
        }


    }, [uid]);
    return (
        <div>
            <CartItemBig item={item} key={item.name} src={item.source} name={item.name} artist="PT" keywords={item.keywords} price={15} prompt_string={item.prompt_string} info={item.info}/>
            <RecommendedBanner></RecommendedBanner>
        </div>

    );
}
//<CartItemBig key={item.name} src={item.source} name={item.name} keywords={item.keywords} price={item.price} prompt_string={item.prompt_string} info={item.info}/> 
//<CartItemBig ></CartItemBig>
//<CartItemBig key={items[0].name} src={items[0].source} name={items[0].name} keywords={items[0].keywords} price={items[0].price} prompt_string={items[0].prompt_string} info={items[0].info}/>

export default ItemPage;
