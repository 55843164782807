import {createSlice, current} from '@reduxjs/toolkit';
import { v4 as uuId } from 'uuid';

const cartSlice = createSlice({
    name:"cart",
    initialState:{
        products: [],
        quantity: 0,
        total: 0,
    },
    reducers:{
        addProduct:(state, action)=>{
            state.quantity += 1;
            action.payload.product.uid = uuId(); //add basketItemId
            state.products.push(action.payload.product);
            state.total += action.payload.price;
        },
        removeProduct:(state, action)=>{
            const id = action.payload.product.uid; 
            state.quantity -= 1;
            state.products = state.products.filter(product => product.uid !== id);
            state.total -= action.payload.price;
        },
        clearBasket: (state) => {
            // Reset the cart state to its initial state
            state.products = [];
            state.quantity = 0;
            state.total = 0;
        },
    },
});

export const {addProduct, removeProduct, clearBasket} = cartSlice.actions;
export default cartSlice.reducer;