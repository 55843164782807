import { collection, getDocs, doc } from "firebase/firestore";
import {storage, firestore} from "../firebase";
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Typography, Paper, ButtonBase, Button, Grid, useMediaQuery, Divider } from '@mui/material';
import myImage from '../assets/logo512.png';
import { useEffect, useState } from "react";

import { CartItemSmall } from "./cartItem";
import { GenericBanner } from "./global";

export default function ItemCollection({keyword}){
    const theme = useTheme();

    //todo rewrite this state to use redux and persist all the data on the frontend as to minimize the number of requests.
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const getItems = async() =>{
            setLoading(true);
            const items = [];
            const querySnapshot = await getDocs(collection(firestore, "artworks"));
            querySnapshot.forEach((doc) => {
                const itemData = doc.data();
                // Add the document ID as a property in the data object
                itemData.linkId = doc.id;
                items.push(itemData);
                console.log(doc.id, " => ", doc.data());
            });
            setItems(items);
            setLoading(false);
        }

        try{
            getItems();
        } catch(err){
            console.error(err);
        }
    }, []);

    // for now just filter to the only relevant info
    let itemList = items.concat(items).concat(items);
    itemList = itemList.filter(item => item.keywords && item.keywords.includes(keyword));

    console.log(keyword);
    //itemList.length = 5;

    return(
        <GenericBanner sx={{minHeight:'600px'}}>
            <Container>
                <Grid container rowSpacing={2} columnSpacing={2}>     
                    {itemList.map(item => {
                        return(
                            <Grid key={item.name+Math.random()} item xs={12} sm={6} md={3}>
                                <CartItemSmall item={item} linkId={item.linkId} key={item.name} src={item.source} name={item.name} keywords={item.keywords} price={item.price} prompt_string={item.prompt_string} info={item.info} card_height={200}/>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </GenericBanner>
    )
}