import { collection, getDocs } from "firebase/firestore";
import {storage, firestore} from "../firebase";
import {Container,Typography, Grid, Item, Button} from "@mui/material";
import {CartItemSmall} from "../components/cartItem";
import ItemCollection from "../components/itemCollection";
import { useEffect, useState} from "react";
import { useParams } from 'react-router-dom';

export default function ShopPage() {
    const {keyword} = useParams();
    return(
        <>
            <ItemCollection keyword={keyword}>
            </ItemCollection>
        </>
    );
};