import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import {ListItemIcon, ListItemButton,Divider, Grid, List, ListItem, TextField} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { GenericBanner } from './global';

const servicePages = [
    {title: 'Customer Service', url: '#'},
    {title: 'Order & Delivery', url: '#'},
    {title: 'Payment', url: '#'}, 
    {title: 'Returning', url: '#'}, 
    {title: 'Warranty', url: '#'}, 
];

const infoPages = [
    {title: 'About', url: '#'},
    {title: 'News', url: '#'}, 
    {title: 'Career', url: '#'},
    {title: 'KvK', url: '#'}, 
    {title: 'Sustainability', url: '#'}, 
];

const socialPages = [
    {title: 'Instagram', url: '#'},
    {title: 'Twitter', url: '#'}, 
    {title: 'TikTok', url: '#'},
    {title: 'Hyves', url: '#'}, 
    {title: '', url: '#'}, 
];

const columns = [
    {title: 'Service', col: servicePages},
    {title: 'About', col: infoPages},
    {title: 'Socials', col: socialPages},
];

const Banner = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '0px',
    backgroundColor: theme.palette.secondary.lighter,
}));

const backgroundImageUrl = `${process.env.PUBLIC_URL}/bgPatterns/pattern_2.png`;

const componentStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundRepeat: 'repeat', // You can use 'repeat-x' or 'repeat-y' for single-axis repeats
};

function Footer() {

  const theme = useTheme();

  return (
    <Banner>
        <Container sx={{width:'100%'}}>
        <Grid container>
            {columns.map((column) => (
                <Grid item xs={6} md={3} sx={{p:3}} key={column.title}>
                    <List>
                        <ListItem sx={{p:0}}>
                            <Typography>
                                {column.title}
                            </Typography>
                        </ListItem>
                        {column.col.map((page) => (
                            <ListItem sx={{p:0}} key={page.title} >
                                <Link
                                    variant="button"
                                    underline="none"
                                    color="text.secondary"
                                    key={page.title}
                                    href={page.url}
                                    sx={{ fontSize:'11px'}}>
                                    {page.title}
                                </Link>
                            </ListItem>
                        ))}

                    </List>
                </Grid>
            ))}

            <Grid item xs={6} md={3} sx={{pt:4}} >
                <Typography>
                    Subscribe to the newsletter!
                </Typography>
                <Typography sx={{fontSize: '11px'}}>
                    Special offers and updates for the newest products. Sign up for the email newsletter.
                </Typography>
                <Grid container alignItems="center" spacing={1} sx={{}}>
                    <Grid item xs={12} sm={6} md={6} >
                        <TextField id="newsletter" color="info" label="Email" variant="outlined" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Button variant="contained" color="primary">
                        Subscribe
                        </Button>
                    </Grid>
                    </Grid>
            </Grid>
        </Grid>
    </Container>
    
    </Banner>
  );
}
export default Footer;