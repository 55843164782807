export class CustomerOrder {
    constructor (id, firstname, lastname, email, streetAddress, city, postalcode, country, products) {
        this.id = id;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.streetAddress = streetAddress;
        this.city = city;
        this.postalcode = postalcode;
        this.country = country;
        this.products = products;
        //add artworks that were ordered
    }
    toString() {
        return this.firstname + ', ' + this.lastname + ', ' + this.city;
    }
}

export const SerializeCustomerOrderObject = (obj) => {
    return JSON.parse(JSON.stringify(CustomerOrder));
};
  
export const DeserializeCustomerOrder = (obj) => {
    return new CustomerOrder(obj);
};

// Firestore data converter
export const CustomerOrderConverter = {
    toFirestore: (customerOrder) => {
        return {
            id : customerOrder.id,
            firstname : customerOrder.firstname,
            lastname : customerOrder.lastname,
            email : customerOrder.email,
            streetAddress : customerOrder.streetAddress,
            city : customerOrder.city,
            postalcode : customerOrder.postalcode,
            country : customerOrder.country,
            products : customerOrder.products,
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new CustomerOrder(data.id, data.firstname, data.lastname, data.email, data.streetAddress, data.city, data.postalcode, data.country, data.products);
    }
};