import React, { useState, useEffect, useContext} from "react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Slide } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomSnackbar() {
  const snackbarCtx = useContext(SnackbarContext);
  const handleClick = () => {
    snackbarCtx.isDisplayed(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    snackbarCtx.isDisplayed(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
    <Snackbar open={snackbarCtx.isDisplayed} autoHideDuration={6000} onClose={handleClose}TransitionComponent={TransitionSnackBar} anchorOrigin={{vertical : 'bottom', horizontal : 'center'}}>
      <Alert onClose={handleClose} severity={snackbarCtx.severity} sx={{ width: '100%' }}>
        {snackbarCtx.msg}
      </Alert>
    </Snackbar>
  </Stack>
  );
}

const SnackbarContext = React.createContext({
  isDisplayed: false,
  displayMsg: (msg, severity) => {},
  onClose: () => {},
});

function TransitionSnackBar(props){
    return <Slide {...props} direction="up"/>;
}

const SnackBarContextProvider = (props) => {
    var timer;
    const [msg, setMsg] = useState("");
    const [severity, setSeverity] = useState("");
    const [isDisplayed, setIsDisplayed] = useState(false);
    const displayHandler = (msg, severity) => {
      setMsg(msg);
      setSeverity(severity)
      setIsDisplayed(true);
       timer = setTimeout(() => {
        closeHandler();
      }, 3000); // close snackbar after 3 seconds
    };
    const closeHandler = () => {
      clearTimeout(timer);
      setIsDisplayed(false);
    };
    return (
      <SnackbarContext.Provider
        value={{
          msg,
          isDisplayed,
          severity,
          displayMsg: displayHandler,
          onClose: closeHandler,
        }}
      >
        {props.children}
      </SnackbarContext.Provider>
    );
  };

export {CustomSnackbar, SnackbarContext, SnackBarContextProvider};