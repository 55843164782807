import { getFunctions, httpsCallable } from "firebase/functions";
import { CustomerOrder, SerializeCustomerOrderObject } from "../models/CustomerOrderModel";


function PrintOrder(id, firstname, lastname, email, streetAddress, city, postalcode, country, products){
    const functions = getFunctions();
    const customerOrder = new CustomerOrder(id, firstname, lastname, email, streetAddress, city, postalcode, country, products);
    const printOrder = httpsCallable(functions, 'PrintOrder');

    printOrder(customerOrder).then(paymentUrl => {
      // Use the paymentUrl in your frontend
      console.log(paymentUrl);

      //redirect user
      window.location.href = paymentUrl.data.link;
    })
    .catch(error => {
      console.log("Something went wrong getting the paymentUrl");
    });
};

export default PrintOrder;