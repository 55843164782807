import {configureStore, combineReducers} from "@reduxjs/toolkit";
import cartReducer from "./cartRedux";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const persistedCartReducer = persistReducer(persistConfig, cartReducer);

const store = configureStore({
    reducer:{
        //cart: cartReducer,
        cart: persistedCartReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    devTools: true,
});

let persistor = persistStore(store);

export {store, persistor};