import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import {ListItemIcon, ListItemButton, Divider, Badge} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from "@mui/material/styles";

import { connect } from 'react-redux';
import signInWithGoogle from '../functions/signIn';
import { SnackbarContext } from './snackbar';
import { BasketDrawerContext } from './basketDrawer';
import {useSelector} from 'react-redux';
import { useContext } from 'react';

import icon_32x32 from '../assets/icon_32x32.png';
import {StyledLink} from '../components/global';

const pages = [
    //{title: 'About', url: '#'},
    //{title: 'My Table', url: '#'},
    //{title: 'Home', url: '#'}, 
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const cartQuantity = useSelector(state=>state.cart.quantity);
  const basketCtx = useContext(BasketDrawerContext)
  const snackbarCtx = useContext(SnackbarContext);
  const clickAccountIcon = () => {
      snackbarCtx.displayMsg("What would you need an account for?", "warning");
  };
  const clickSearchIcon = () => {
    snackbarCtx.displayMsg("Searching? Maybe try that one later :)", "warning");
};

  return (
    <AppBar
        position="sticky"
        elevation={1}
        sx={{ borderBottom: (theme) => `2px solid ${theme.palette.divider}` }}
    >
    <Container sx={{width:1}}>
        <Toolbar sx={{ flexWrap: 'wrap' }}>

          <Typography 
              variant="h1"
              noWrap sx={{
                fontStyle: "italic",
                fontSize: 23,
                flexGrow: 1,
                letterSpacing: "0.1em"
                }}
            >
              <StyledLink to={'/'} style={{fontSize:'30px'}}>
                Prompted
              </StyledLink>
            </Typography>
            {pages.map((page) => (
                    <Link
                        variant="button"
                        color="text.secondary"
                        key={page.title}
                        href={page.url}
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        {page.title}
                    </Link>
            ))}
          <ListItemButton onClick={()=>{basketCtx.setIsDisplayed(true)}} sx={{justifyContent: "center", maxWidth: 50}}>
            <ListItemIcon sx={{display: "flex", justifyContent: "center"}}>
            <Badge badgeContent={cartQuantity} color="secondary" >
                <ShoppingCartIcon/>
            </Badge>
            </ListItemIcon>
          </ListItemButton>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItemButton onClick={clickSearchIcon} sx={{justifyContent: "center", maxWidth: 50}}>
            <ListItemIcon sx={{display: "flex", justifyContent: "center"}}>
              <SearchIcon/>
            </ListItemIcon>
          </ListItemButton>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItemButton onClick={clickAccountIcon} sx={{justifyContent: "center", maxWidth: 50}}>
            <ListItemIcon sx={{display: "flex", justifyContent: "center"}}>
              <PersonIcon/>
            </ListItemIcon>
          </ListItemButton>
        </Toolbar>
    </Container>
  </AppBar>
  );
}
export default ResponsiveAppBar;