const ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960, // Define 'md' to be 960 pixels wide (you can adjust this value as needed)
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
        primary: {
          main: '#D9F6FF',
        },
        secondary: {
          main: '#f2d3ff',
          light: '#f9e9ff',
          lighter: '#fbf2ff',
        },
        divider: '#cccf3e4',
        info: {
          main: '#9cefc4',
        },
        text: {
          primary: '#aaaf3e4',
          extra: '#0c0c0c',
        },
        shadedPrimary: {
          main: "#E0F7FF",
        },
      },
      background: {
        default: '#ffffff',
        paper: '#ecefec',
      },
    typography: {
        fontFamily: [
            'Share Tech Mono',
            'Roboto',
            '"Helvetica Neue"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#D9F6FF55',
            //backgroundImage: 'repeating-linear-gradient(-45deg, transparent 0 20px, #fcfcfc 20px 40px)',
          },
        },
      },
    }
};

export default ThemeOptions;