import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import {connect} from 'react-redux';
import { useEffect, useState, useContext, useRef } from 'react';

import Home from './pages/home';
import About from './pages/about';
import ShopPage from './pages/shoppage';
import ItemPage from './pages/item';
import Checkout from './pages/checkout';
import Terms from './pages/terms';
import OrderSucces from "./pages/orderSuccess";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper, Grid, Button, Switch, Typography, Card, Container, Box, Alert, AlertTitle, CircularProgress} from "@mui/material/";

import ResponsiveAppBar from "./components/header";
import DefaultTheme from "./assets/theme";
import NavBar from "./components/navbar";
import Banner from "./components/banner";
import Footer from "./components/footer";
import FooterCopyright from './components/footerCopyright';
import {CustomSnackbar, SnackbarContext} from "./components/snackbar";
import CssBaseline from '@mui/material/CssBaseline';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

import { app, auth, functions, storage, firestore, analytics } from './firebase';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

import Test from './functions/callAddUser';
import PrintOrder from './functions/printOrderFunctions';
import { BasketDrawerContext, BasketDrawer } from './components/basketDrawer';
//import Authenticate from '../functions/PrintAPI';

function signOutUser() {
  // Sign out of Firebase.
  signOut(auth);
}

// Initialize firebase auth
function initFirebaseAuth() {
  // Listen to auth state changes.
  onAuthStateChanged(auth, authStateObserver);
}

// Returns the signed-in user's profile Pic URL.
function getProfilePicUrl() {
  return auth.currentUser.photoURL;
}

// Returns the signed-in user's display name.
function getUserName() {
  return auth.currentUser.displayName;
}

// Triggers when the auth state change for instance when the user signs-in or signs-out.
function authStateObserver(user) {
  if (user) { // User is signed in!
    // Get the signed-in user's profile pic and name.
    var profilePicUrl = getProfilePicUrl();
    var userName = getUserName();
  } else { 

  }
}

function TestButtons() {

    return (
        <div>
            <Button onClick={Test} color="secondary" variant="contained"> TEST FUNCTION</Button>
            <Button onClick={PrintOrder} variant="contained"> TEST PRINT ORDER BACKEND</Button>
        </div>
    )
}

function App() {

  //theme
  let theme = createTheme(DefaultTheme);

  initFirebaseAuth();

    //info snackbar
    const snackbarCtx = useContext(SnackbarContext);
    const clickHandler = () => {
        snackbarCtx.displayMsg("HELLO", "warning");
    };

  return (
    <div>
      
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Container maxWidth="false" disableGutters elevation='2'>
          <ResponsiveAppBar></ResponsiveAppBar>
          <NavBar></NavBar>
          </Container>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/collection/:keyword" element={<ShopPage />}></Route>
          <Route path="/orderSuccess" element={<OrderSucces />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/item/:uid" element={<ItemPage />}></Route>
        </Routes>
        <Footer/>
        <FooterCopyright/>
        {snackbarCtx.isDisplayed && <CustomSnackbar />}
        {<BasketDrawer/>}
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
