import React from 'react';
import Typography from '@mui/material/Typography';
import Banner from '../components/banner';
import alienDrawing from '../assets/alien_drawing2.png';
import ItemCollection from '../components/itemCollection';
import RecommendedBanner from '../components/productsBanner';
function Home() {
    return (
        <>
            <Banner title="L'Art de Artificial" 
                    content="Artificial art, or digital art, is a form of art created using digital technology. One of the main advantages of artificial art is the ability to create bold and vivid colors, intricate patterns, and dynamic movements that may be difficult to achieve with traditional mediums. The precision and control offered by digital technology allows for highly detailed and realistic images, or the exploration of abstract and experimental styles. The beauty of artificial art lies in the combination of artistic vision and the limitless possibilities of digital technology."
                    image={alienDrawing}>
            </Banner>
            <RecommendedBanner title="L'Art de Artificial" 
                    content="Artificial art, or digital art, is a form of art created using digital technology. One of the main advantages of artificial art is the ability to create bold and vivid colors, intricate patterns, and dynamic movements that may be difficult to achieve with traditional mediums. The precision and control offered by digital technology allows for highly detailed and realistic images, or the exploration of abstract and experimental styles. The beauty of artificial art lies in the combination of artistic vision and the limitless possibilities of digital technology."
                    image={alienDrawing}>
            </RecommendedBanner>
        </>
    );
}

export default Home;