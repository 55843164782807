import React from 'react';
import Typography from '@mui/material/Typography';
import Banner from '../components/banner';
import {clearBasket} from "../redux/cartRedux";
import { useDispatch } from 'react-redux';  
import thankYouImage from '../assets/alien_drawing2.png';

function SuccessPage() {
    const dispatch = useDispatch();
    dispatch(clearBasket());
    return (
        <>
            <Banner title="Thank you for ordering; this might be the beginning of something good ❤️" content="The item will be shipped to you soon™." image={thankYouImage}></Banner>
        </>
    );
}

export default SuccessPage;