import React from 'react';
import { useEffect, useState, useContext } from "react";
import { Button, CardActionArea, CardActions, Chip,Grid, Divider} from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import { TypoGraphy, useMediaQuery } from "@mui/material";
import { useTheme, styled, } from '@mui/material/styles';
import { Box, Container, Paper, ListSubheader, ListItemText, ListItem, Stack, InputLabel, MenuItem, FormControl, Select, Typography, CardMedia, CardContent, Card } from '@mui/material';
import {addProduct, removeProduct} from "../redux/cartRedux";
import { useDispatch } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Fab} from '@mui/material';
import myImage from '../assets/logo512.png';
import emptyCanvas1 from '../assets/empty_canvas_pink_bg.jpg';
import {SnackbarContext} from './snackbar';
import { v4 as uuId } from 'uuid';
import { Link } from 'react-router-dom'
import { StyledLink, GenericBanner} from "./global";

const PrintVariantsPoster = [
    {id: 'poster_a4_sta', variant: 'Poster', name: 'Poster A4', price: '€2,90 excl.', size:'210x297mm'},
    {id: 'poster_hd_30x40',variant: 'Poster', name: 'Poster HD 30x40cm', price: '€5,49 excl.', size:'300x400mm'},
    {id: 'poster_hd_60x90',variant: 'Poster', name: 'Poster HD 60x90cm', price: '€11,66 excl.', size:'600x900mm'},
    {id: 'poster_hd_A0_sta',variant: 'Poster', name: 'Poster HD A0', price: '€17,41 excl.', size:'841x1189mm'}
];

const PrintVariantsCanvas = [
    {id: 'canvas_20x30', variant: 'Canvas', name: 'Canvas 20x30cm', price: '€x.xx excl.', size:'200x300mm'},
    {id: 'canvas_30x40', variant: 'Canvas', name: 'Canvas 30x40cm', price: '€x.xx excl.', size:'300x400mm'},
    {id: 'canvas_60x90',variant: 'Canvas', name: 'Canvas 60x90cm', price: '€x.xx excl.', size:'600x900mm'}
];

const PrintVariants = [
    {name: 'Poster', variantGroup: PrintVariantsPoster},
    {name: 'Canvas', variantGroup: PrintVariantsCanvas}
];

function CartItemBasket({uid, name, src, price=15, ai_machine, keywords, prompt_string, card_height, variant}) {
    const dispatch = useDispatch();
    return (
      <Card elevation={4}>
            <Fab onClick={()=>{dispatch(removeProduct({product:{name, variant, uid}, quantity:1, price}));}} color="secondary" size="small" aria-label="delete" sx={{position:"absolute", right:"20px", m:1}}>
                <DeleteForeverIcon/>
            </Fab>
        <CardActionArea sx={{p: "0.5em", height: card_height}}>
            
          <CardMedia
            component="img"
            height="75%"
            image={src}
            alt="basket item"
            sx={{objectFit: "contain"}}
          />
          <CardContent>

              <Grid container sx={{pb:5}}>
                  <Grid item xs={10}>
                        <Typography fontSize={14}>{variant}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                        <Typography fontSize={14}>&euro;{price}</Typography>
                  </Grid>
              </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
function CartItemSmall({uid, linkId, name, src, price=15, ai_machine, keywords, prompt_string, card_height}) {
  return (
    <Card elevation={4}>
      <StyledLink to={`/item/${linkId}`}>
      <CardActionArea sx={{p: "0.5em", height: card_height}}>
        <CardMedia
          component="img"
          height="75%"
          image={src}
          alt="green iguana"
          sx={{objectFit: "contain" }}
        />
        <CardContent>
            <Grid container sx={{pb:5}}>
                <Grid item xs={8}>
                    <Typography variant="h6" align="center" sx={{m:0}}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Chip size="small" icon={<EuroIcon style={{ color: 'grey' }}/>} label={price}
                      sx={{
                          backgroundColor: (theme) => theme.palette.secondary.main,
                          color: (theme) => 'grey',
                          m: 0.5,
                          p: 0.5,
                          fontWeight: 'bold',
                          fontSize: '16px',
                        }}/>
                </Grid>
            </Grid>
        </CardContent>
      </CardActionArea>
      </StyledLink>
    </Card>
  );
}

//show the image on top of the container image, not yet working correctly
const ImageOverlay =  ({ image, background }) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <img
        src={background}
        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
      />
      <img
        src={image}
        alt={"First Image"}
        style={{ position: 'absolute', top: '32.5%', left: '43.5%', width: '48%', height: '44%', objectFit: 'contain' }}
      />
    </div>
  );
};

//this component is passed in async way so arguments might be undefined, therefore use default values as to prevent errors
function CartItemBig({item, uid, name, src, artist, price, ai_machine="Dall-E", keywords="", prompt_string}) {
    const image1 = src;
    const containerCanvas = emptyCanvas1;
    const [image, setImage] = useState(image1);
    const [quantity, setQuantity] = useState(1);
    const [variant, setVariant] = React.useState('');
    const dispatch = useDispatch();

    //info snackbar
    const snackbarCtx = useContext(SnackbarContext);
    const uuid = uuId();

    const handleAddItem = () => {
        if(variant == ""){
            snackbarCtx.displayMsg("Please select a product variant.", "warning");  
        }else{
            dispatch(addProduct({product:{uid, item, variant}, quantity, price}));
        }
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };


    return (
    <GenericBanner>
        <Container>
                <Grid container>
                    <Grid item md={5} xs={12} > 
                        <Card elevation={1} sx={{m:2}}>
                            <CardActionArea sx={{p: "0.4em"}}>
                        {/*<CardMedia  onMouseOver={() => setImage(containerCanvas)}
                                    onMouseOut={() => setImage(image1)}
    component="img" height="500" image={image} alt="product image" sx={{objectFit: "contain"}}/>*/}
                            <div
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              style={{height: '400px' }}
                            >
                              {!isHovered ? (
                                <CardMedia component="img" height="500" image={image1} alt="product image" sx={{objectFit: "contain"}}/>
                              ) : (
                                <ImageOverlay image={image1} background={containerCanvas}/>
                              )}
                            </div>
                        <CardContent>
                        </CardContent>
                    </CardActionArea>
                </Card>                        
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Box sx={{my:'25px', p:5, backgroundColor:'#ff00ff00'}}>
                            <Grid item xs={12} sm={12} container>
                                <Grid item xs container direction="column" spacing={2} sx={{height:400, bgColor:'#ff0000'}}>
                                    <Typography variant="h2" sx={{mb:1}}>
                                    {name}
                                        <Typography component="span" sx={{display:"inline"}} variant="body2" gutterBottom>
                                            - creator: 
                                            <Typography component="span"  sx={{display:"inline", fontStyle:"italic"}} >{artist} & {ai_machine}</Typography>
                                        </Typography>
                                    </Typography>
                                    {/*<Typography variant="body2" align="justify" gutterBottom sx={{letterSpacing: '0.8px', lineHeight: '1.7'}}>
                                        {prompt_string}
                                    </Typography>*/}

                                    <SelectProduct functions={[variant, setVariant]}></SelectProduct>
                                    <Button onClick={handleAddItem}sx={{mt:1}} fullWidth variant="contained" color="secondary">
                                        Add to cart
                                    </Button>
                                    
                                    <Divider sx={{mt:1, mb:1}}></Divider>
                                    <ProductTagChips keywords={keywords}></ProductTagChips>
                                </Grid>

                            </Grid>


                        </Box>

                    </Grid>
                </Grid>

        </Container>
    </GenericBanner>
)}

const StyledSelect = styled(Select)({
    root: {
        display: "flex",
        alignItems: "center"
      }
});

  


// lists all the products for the product variant dropdown
// todo import the prices etc from the backend
function SelectProduct(props) {
    const [variant, setVariant] = props.functions;
  const handleChange = (event) => {
    setVariant(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth color="secondary" focused={false} size="medium">
        <InputLabel id="item-set-variant-label">Variant</InputLabel>
        <Select
          labelId="item-set-variant-label"
          id="item-set-variant-select"
          value={variant}
          label="Variant"
          onChange={handleChange}
          sx={{backgroundColor: "#fafafaa0"}}
        >

            {PrintVariants.map((group) => {
                return([
                    /*return array cuz fragment is not allowed within Select*/
                    <ListSubheader keywords={group.name}>{group.name}</ListSubheader>, 
                    group.variantGroup.map((variant) => (
                        
                        <MenuItem value={variant.name} key={variant.name} component="div">
                             <ListItem sx={{m:0, p:0, px:1}}>
                                <ListItemText primary={variant.name} disableTypography={true} sx={{p:0, m:0}}/>
                                <ListItemText sx={{textAlign: "right", p:0, m:0 }} secondary={variant.price} />
                             </ListItem>
                        </MenuItem>
                    ))
                ]);
            })}
        </Select>
      </FormControl>
    </Box>
  );
}

function ProductTagChips({keywords}){
  return (
    <Stack direction="row" spacing={1} sx={{m:0}}>
        {keywords.split(",").map((keyword) => (
            <Chip label={keyword} key={keyword} color="secondary" variant="filled" size="small"/>
        ))}
    </Stack>
  );
}

export {CartItemSmall, CartItemBig, CartItemBasket};